const idPortalApi = "4467a8baabd7eb041b165faf3e4346e0aa2da10b";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "http://cmfortuna.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmfortuna.ma.gov.br",
  NomePortal: "Fortuna",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/48cLBorYqGLxuGaH9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15879.38871767213!2d-44.15274025!3d-5.735137450000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78618dad134d73f%3A0x8f6ce7ebb9cf8959!2sFortuna%2C%20MA%2C%2065695-000!5e0!3m2!1spt-BR!2sbr!4v1716918036643!5m2!1spt-BR!2sbr",
};
